/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import Modal from 'react-modal';
import { useNavigate, Link } from 'react-router-dom';
import TrunkAssignementStepTwo from "./../NonCarrierNumber/TrunkAssignementStepTwo";

 
const Step1 = ({ onNext,token, formData, setFormData }) => {
   
    const [companyList, setcompanyList] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [importType, setImportType] = useState(0);
    const [validationErrors, setValidationErrors] = useState({
        file: false,
        cname: false,
    });
    const [file, setFile] = useState('');
    const navigate = useNavigate();
 
    useEffect(() => {
       if(token){
        getCustomerNameList();
       } 
    }, [token]);
    const errorListRef = useRef(null);

    useEffect(() => {
        if (Object.entries(errorList).length > 0) {
          errorListRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [errorList]);

    const getCustomerNameList = async () => {
        try {
          
            const response = await axios.get(`${baseUrl}/active-user-company`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            const allArray = response.data.filter(item => item.id !== 1);
            setcompanyList(allArray);
        } catch (error) {
           console.log(error);
            
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
      };

    const handleNextClick = async(e) => {
        e.preventDefault();
        const newValidationErrors = {
            file:file == '',
            cname: selectedValue === '',
          };
         
          setValidationErrors(newValidationErrors);
          const hasErrors = Object.values(newValidationErrors).some((error) => error);
     
        if(!hasErrors){
            setButtonDisabled(true);
            setPopupOpen(true);
            const newData = new FormData();
            newData.append("file", file);               
            newData.append('company_id', selectedValue);

            let response;
            if(importType === 1){
                response =  axios.post(`${baseUrl}/import-others-inventory`, newData,{
                    headers: {
                         Authorization: `Bearer ${token}`,
                         "Content-Type": "multipart/form-data",
                    }
                });
            }else if(importType === 2){
                const response =  await axios.post(`${baseUrl}/import-non-supported-carrier-number-validation`, newData,{
                    headers: {
                         Authorization: `Bearer ${token}`,
                         "Content-Type": "multipart/form-data",
                    }
                });
                const allArray = response.data;
                setPopupOpen(false);
                setButtonDisabled(false);
                if(allArray.data){
                    setFormData({ ...formData,  importNumberList: allArray.data });
                    onNext();
                }else{
                    setErrorList(allArray);
                }
            }else{
                response =  axios.post(`${baseUrl}/import-did-inventory`, newData,{
                    headers: {
                         Authorization: `Bearer ${token}`,
                         "Content-Type": "multipart/form-data",
                    }
                });
            }
            if(importType == 0 || importType == 1){
                navigate("/order-new-number-success", { state: {
                    importInventory : true
                } }); 
            }
           
        }
       
    };

    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);
        setFormData({ ...formData,  company_id: e.target.value });
    }; 
    
    const handleRadioChange = (e) => {
        setImportType(Number(e.target.value));
    };
 
    return (
      <>
        {popupOpen && <Loader /> }
                                   
        <div className="row">
            <div className="col-8">
                <div className="left-title">
                    <h1>Import DID Inventory</h1>
                </div>
            </div>
            <div className="col-4 top-right-buttons">   
                <div className="export-btn addnew-button"><Link to="/import-inventory-history">History</Link></div>
                <div className="export-btn addnew-button"><Link to="/number-management"><span>&larr;</span> Back</Link></div>
            </div>                  
        </div>
        <div className="common-white-shadow-background setting-section">
            <div className="row">
                <h3 className='comapny-label'>Select Company</h3>
                <div className="inputbox">
                    <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                        <option value="">Select Company</option>
                        {companyList.map((comp) => (                                                                    
                                <option key={comp.id} value={comp.id}>
                                    {comp.cname}
                                </option>  
                            ))}
                    </select>
                </div>
            </div>
            {validationErrors.cname && <p className="error">Please select a value from the dropdown.</p>}
        </div>
            
        <div className="common-white-shadow-background setting-section">
                <div className="row">
                <h3>Import Type</h3>
                <div className="settingbox radio-standard-box">
                    <label for="nonSupportedCarrier"><input type="radio" id="nonSupportedCarrier" name="method_type" value={2} checked={importType === 2} onChange={handleRadioChange} />
                    &nbsp;&nbsp;BBD Numbers (Update Sansay/NSS)</label> 
                    <label for="pastNumber"><input type="radio" id="pastNumber" name="method_type" value={0} checked={importType === 0} onChange={handleRadioChange} />
                    BBD Numbers (Do not update Sansay/NSS)</label>          
                    <label for="fileUpload"><input type="radio" id="fileUpload" name="method_type" value={1} checked={importType === 1} onChange={handleRadioChange} />
                    Non-BBD Numbers (Will not update Sansay/NSS)</label>
                </div>
                
            </div>
        </div>

        <div className="common-white-shadow-background setting-section">
            <div className="row">
        
                <h3>Upload File (Using Template)</h3>
                <div className="download-templte"> 
                {/* {importType === 0 && <a  className="newrow" target="_blank" href="/files/ImportInventory_Template.xlsx?v=123456789" download="ImportInventory_Template.xlsx">Download Template</a>} */}
                {importType === 0 && <a  className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/Import-DID.xlsx`} download="ImportInventory_Template.xlsx">Download Template</a>}
                {importType === 1 && <a  className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/ImportInventory_Others_Numbers_Template.xlsx`}>Download Template</a>}                       
                {importType === 2 && <a  className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/Import-DID.xlsx`} download="ImportInventory_Template.xlsx">Download Template</a>}
                {/* {importType === 2 && <a  className="newrow" target="_blank" href="/files/ImportInventory_Template.xlsx?v=123456789" download="ImportInventory_Template.xlsx">Download Template</a>} */}
                    <p>Submissions must be in the format of this template</p>
                </div>
                <div className="file-upload-field">
                    <div className="upload-btn-wrapper">
                          <input type="file" name="myfile" onChange={handleFileChange} placeholder="Select your local file based on our template" />
                        </div>
                </div>
                {validationErrors.file && <p className="error">Please choose a file.</p>}
            </div>
        </div>
            
        <div className="row footer-row">                
            {(importType === 0 || importType === 1) && <a href="#" className="next" onClick={handleNextClick}>Import</a>}
            {importType === 2 && <a href="#" className={buttonDisabled ? 'next disabled-link' : 'next'} onClick={handleNextClick}>Next</a>}
        </div>

        <div style={{'color':"red","paddingBottom":"30px"}} ref={errorListRef}>
            { Object.entries(errorList).length > 0 && <p>Please resolve below errors</p>}
                <table className="tablebox search-result-table order-result-table">                                        
                    <tbody>
                        {Object.entries(errorList).map(([key, value]) => ( key !== 'statusCode' && key !== 'show' && (
                            <tr key={key}>
                                <td><strong>{key}:</strong></td>
                                <td >{key === 'status' ? 
                                    (   value
                                          .split(',')
                                          .map((item, index) => (
                                            <p key={index}>{item.trim()}</p>
                                          ))
                                    )  :
                                    key === 'result' && Array.isArray(value) ? (
                                        value.map((item, index) => (<p key={index}>{item}</p>))
                                    ) : (
                                        value
                                    )}
                                </td>
                            </tr>
                        )))}
                    </tbody>
                </table>
            </div>        
        </> 
    )
}


const ImportDIDInventory = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [cid, setCid] = useState('');
    const [formData, setFormData] = useState('');
    const [step, setStep] = useState(1);

    const navigate = useNavigate();
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(role){
            checkAccess();
        }
    }, [role]);

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);        
        }
    }


    const checkAccess = async () => {
        if(role > 2){
            navigate('/number-management');
        }
    }

    
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleNext = () => setStep(step + 1);
    const handleBack = () => setStep(step - 1);
 
    const renderStep = () => {
        switch (step) {
          case 1:
            return <Step1 onNext={handleNext} token={token} formData={formData} setFormData={setFormData} />;
          case 2:
            return <TrunkAssignementStepTwo token={token} formData={formData} setFormData={setFormData} />;

          default:
            return null;
        }
    };
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
                <div className="content-page">
                    {renderStep()}
                </div>
            </div>
        </div>
    )
}
 
export default ImportDIDInventory