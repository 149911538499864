import React, { useEffect, useState, useRef } from 'react'
import jwt_decode from "jwt-decode";
import Select from 'react-select';
import Navbar from '../../Navbar'
import axios from 'axios';
import baseUrl from '../../BaseUrl';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Header from '../../Header';
import Loader from '../../Loader';
import Pagination from 'react-js-pagination';
import formatDateTimeToPST from '../../PSTDateConvert';
import DatePicker from 'react-datepicker';
import { saveAs } from 'file-saver';


const CiaNumberSetting = () => {
    const location = useLocation();
    const { compid, filter } = location.state || {};
    const [name, setName] = useState('');
    const [timeZone, seTimeZone] = useState('');
    const [cid, setCid] = useState('')
    const [token, setToken] = useState('');
    const [access, setAccess] = useState([]);
    const [company, setCompany] = useState([]);
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [casAccess, setCASAccess] = useState(false);
    const [showNoAccessMessage,setShowNoMessageAccess] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(compid);
    const [validationErrors, setValidationErrors] = useState({
        file: false,
        cname: false,
      });
    const [file, setFile] = useState('');
    const errorListRef = useRef(null);
    
 
    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
    }, []);

    useEffect(() => {
        checkAccess();
    }, [role]);


    useEffect(()=>{
        if(selectedCompany && token){
            getCustomerCASAccess(selectedCompany);
        }
    },[selectedCompany, token]);

    useEffect(() => {
        // Scroll to the errorListRef if Object.entries(errorList).length > 0
        if (Object.entries(errorList).length > 0) {
            console.log("scroll");
          errorListRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [errorList]);


    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
            setCid(decoded.cid)
            seTimeZone(decoded.timezone);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const checkAccess = () => {
        if(role > 2){
            navigate('/dashboard');
        }
    }


    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            
        } catch (error) {
           console.log(error);
        }
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        const allArray = response.data.filter(item => item.id !== 1);
        setCompany(allArray);
        // setCompany(response.data);
    }

  
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
            // setCid(decoded.cid)
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getCustomerCASAccess = async (cid) => {
        try {
            setPopupOpen(true);
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            console.log(response.data);
            setPopupOpen(false);
            if(response.data.cia_enable_switch === 1){
                if(response.data.cia_account_type === 0 && role > 2){
                        setCASAccess(false)
                        setShowNoMessageAccess(true)
                }else{
                    setCASAccess(true)
                   
                    setShowNoMessageAccess(false)
                }
            }else{
                setCASAccess(false)
                setShowNoMessageAccess(true)
            }
            
        } catch (error) {
           console.log(error);
           setPopupOpen(false);
        }
    };


    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value);  
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleNextClick = async(e) => {
        e.preventDefault();
        const newValidationErrors = {
            file:file == '',
            cname: selectedCompany === '',
            

          };
         
          setValidationErrors(newValidationErrors);
          const hasErrors = Object.values(newValidationErrors).some((error) => error);
     
        if(!hasErrors){
           
            const newData = new FormData();
                newData.append("file", file);               
                newData.append('company_id', selectedCompany);
                setPopupOpen(true);
                setButtonDisabled(true);
                const response =  await axios.post(`${baseUrl}/cia-inventory-number-setting`, newData,{
                        headers: {
                             Authorization: `Bearer ${token}`,
                             "Content-Type": "multipart/form-data",
                        }
                    });
               
                    setPopupOpen(false);
                    setButtonDisabled(false);
                    const allArray = response.data;
                    console.log(response.data);
                    if(response.data.status == false){
                        setErrorList(allArray.mergedErrors);
                    }else {
                        navigate("/order-new-number-success", { state: {
                            ciaNumberSetting : true
                        } }); 
                    }
                    
               
        }
       
    };

    const gotoSwitchScreen = (e) => {
        e.preventDefault();
        const state = { state: {compid : selectedCompany}}
        navigate("/imports-list", state);
    }

   

    

  return (
    <div className="panelbox">
        <Navbar token={token} access={access}/>
        <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-8">
                            <div className="left-title">
                                <h1>E-NRMS Enable</h1>
                            </div>
                        </div>
                        <div className="col-4 top-right-buttons">
                            <div className="export-btn addnew-button"><Link to="/cia-number-setting-history">History</Link></div>
                            <div className="addnew-button">
                                <a href="#" onClick={(e) => gotoSwitchScreen(e)}>Back</a>
                            </div>
                        </div>
                    </div>

                    {(role <= 2) && <div className="common-white-shadow-background setting-section">
                        <div className="row">
                            <h3 className='comapny-label'>Select Company</h3>
                            <div className="inputbox">
                                <select name="" id=""  value={selectedCompany} onChange={(e) => handleCompanyChange(e)}>
                                    <option value="">Select Company</option>
                                    {company.map((comp) => ( 
                                            <option key={comp.id} value={comp.id}>
                                                {comp.cname}
                                            </option>  
                                        ))}
                                </select>
                            </div>
                        </div>
                        {validationErrors.cname && (<p className="error">Please select a value from the dropdown.</p>)}
                    </div>}

                    {showNoAccessMessage && 
                    <div className="common-white-shadow-background setting-section">
                        <div className="row error">You don't have access to the features.</div>
                    </div>}

                    {casAccess && <>
                        <div className="common-white-shadow-background setting-section">
                            <div className="row">
                                <h3>Upload File (Using Template)</h3>
                                <div className="download-templte">
                                    <a  className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/ImportInventory_Others_Numbers_Template.xlsx`}>Download Template</a>
                                    <p>Submissions must be in the format of this template</p>
                                </div>
                                <div className="file-upload-field">
                                    <div className="upload-btn-wrapper">
                                        <input type="file" name="myfile" onChange={handleFileChange} placeholder="Select your local file based on our template" />
                                    </div>
                                </div>
                                {validationErrors.file && (<p className="error">Please choose a file.</p>)}
                            </div>
                        </div>
                        <div className="row footer-row">
                            <a href="#" className={buttonDisabled ? 'next disabled-link' : 'next'} onClick={handleNextClick}>Import</a>
                        </div>
                        <div style={{'color':"red","paddingBottom":"30px"}} ref={errorListRef}>{ Object.entries(errorList).length > 0 && <p>Please resolve below errors</p>}<table  className="tablebox search-result-table order-result-table">
                                        
                                        <tbody>
                                            {Object.entries(errorList).map(([key, value]) => (
                                                 key !== 'status'  && (
                                              <tr key={key}>
                                                <td><strong>{key}:</strong></td>
                                                <td >
             {value} 
            </td>
                                              </tr>
                                            )))}
                                        </tbody>
                                    </table></div>
                    </>}
                    
                </div>
        </div>
    </div>
  )
}

export default CiaNumberSetting