/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import { useNavigate, Link } from 'react-router-dom';
 
const Index = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [cid, setCid] = useState('');
    const [expire, setExpire] = useState('');
    // const [users, setUsers] = useState([]);
    // const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [digitMapping, setDigitMapping] = useState(false);
    const [userDigitMapping, setUserDigitMapping] = useState(false);
    const [reportAccess, setReportAccess] = useState(false);
    const [userReportAccess, setUserReportAccess] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [inventoryAccess, setInventoryAccess] = useState(false);
    const [userInventoryAccess, setUserInventoryAccess] = useState(true);
    const [isNumberManagementPermission, setIsNumberManagementPermission] = useState(0);
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess(); 
        checkNumberManagementPermission();       
        // getUsers();
    }, []);

    useEffect(() => {
        if(cid){
            getCustomerDigitMappingAccess();
        }
    }, [cid]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
            setCid(decoded.cid);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            setPopupOpen(true);
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            setPopupOpen(false);
            console.log(response.data.results);
            setAccess(response.data.results);
            setUserInventoryAccess(response.data.user_inventory_switch == 0 ? false : true);
            setUserDigitMapping(response.data.user_digit_mapping_switch == 0 ? false : true);
            setUserReportAccess(response.data.user_report_switch == 0 ? false : true);
            
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    
    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
      };
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
            setCid(decoded.cid);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    const getCustomerDigitMappingAccess = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            console.log(response.data);
            setDigitMapping(response.data.digit_mapping_switch === 1 ? true : false);
            setReportAccess(response.data.report_switch === 1 ? true : false);
            setInventoryAccess(response.data.inventory_switch === 1 ? true : false);

        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };

    const checkNumberManagementPermission= async () => {
        const response = await axiosJWT.get(`${baseUrl}/check-number-management-permission`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        setIsNumberManagementPermission(response.data.NumberManagementAccess);
        if(response.data.NumberManagementAccess == 0){
            navigate('/dashboard');
        }
    }
 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Number Management</h1>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                    <div className="row numberboxes">
                        {(hasPermission('ordernewnumber') && isNumberManagementPermission==1) && <div className="col-md-3">
                            <Link to="/order-new-number">
                            <div className="dashboard-box" id="number">
                                <div className="imagebox">
                                    <img src="images/bxs-phone.png" />
                                </div>
                                <h3>Order New Number</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        {/* {hasPermission('modifynumber') &&<div className="col-md-3">
                            <Link to={"/modify-number"}>
                            <div className="dashboard-box" id="userbox">
                                <div className="imagebox">
                                    <img src="images/bxs-edit.png" />
                                </div>
                                <h3>Modify Number</h3>
                               
                            </div>
                            </Link>
                        </div>} */}
                        {(hasPermission('cancelnumber') && isNumberManagementPermission==1)  && <div className="col-md-3">
                        <Link to={"/cancel-number"}>
                            <div className="dashboard-box" id="bbdbox">
                                <div className="imagebox">
                                    <img src="images/bxs-phone-off.png" />
                                </div>
                                <h3>Cancel Number</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        {(hasPermission('viewtransactions') && isNumberManagementPermission==1) && 
                        <div className="col-md-3">
                            <Link to={"/view-order"}>
                            <div className="dashboard-box" id="orderbox">
                                <div className="imagebox">
                                    <img src="images/bxs-cart.png" />
                                </div>
                                <h3>View Orders</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        {(inventoryAccess  && userInventoryAccess && isNumberManagementPermission==1) && <div className="col-md-3">
                        <Link to={"/inventory"}>
                            <div className="dashboard-box" id="inventory">
                                <div className="imagebox">
                                    <img src="images/bxs-paste.png" />
                                </div>
                                <h3>Inventory</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        {/* {hasPermission('viewtransactions') && <div className="col-md-3">
                        <Link to={"/view-transactions"}>
                            <div className="dashboard-box" id="viewtrans">
                                <div className="imagebox">
                                    <img src="images/bx-transfer-alt.png" />
                                </div>
                                <h3>View Transactions</h3>
                                <p>Order and modify phone numbers and features.</p>
                            </div>
                            </Link>
                        </div>} */}                        
                        {(role <=2 && isNumberManagementPermission==1) && <div className="col-md-3">
                        <Link to={"/imports-list"}>
                            <div className="dashboard-box" id="orderbox">
                                <div className="imagebox">
                                    <img src="images/bxs-features.png" />
                                </div>
                                <h3>Imports</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}                        
                        {(hasPermission('ordernewnumber') && isNumberManagementPermission==1) && <div className="col-md-3">
                            <Link to="/features-number">
                            <div className="dashboard-box" id="featurebox">
                                <div className="imagebox">
                                    <img src="images/bxs-features.png" />
                                </div>
                                <h3>Features</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        {(digitMapping && userDigitMapping && hasPermission('ordernewnumber')  && isNumberManagementPermission==1) && <div className="col-md-3">
                            <Link to="/digit-mapping">
                            <div className="dashboard-box" id="userbox">
                                <div className="imagebox">
                                    <img src="images/bxs-edit.png" />
                                </div>
                                <h3>Digit Mapping</h3>
                            </div>
                            </Link>
                        </div>}
                        {(reportAccess && userReportAccess && isNumberManagementPermission==1) && <div className="col-md-3">
                            <Link to="/traffic-and-analytics-reporting">
                            <div className="dashboard-box" id="viewtrans">
                                <div className="imagebox">
                                    <img src="images/bxs-edit.png" />
                                </div>
                                <h3>Traffic Analytics Report</h3>
                            </div>
                            </Link>
                        </div>}
                        {role <= 2 && <div className="col-md-3">
                            <Link to={"/reconcile-active-numbers"}>
                            <div className="dashboard-box" id="orderbox">
                                <div className="imagebox">
                                    <img src="images/bxs-cart.png" />
                                </div>
                                <h3>Reconcile active numbers</h3>
                            </div>
                            </Link>
                        </div>}
                        {/* {role <= 2 && <div className="col-md-3">
                        <Link to={"/daily-order-list"}>
                            <div className="dashboard-box" id="viewtrans">
                                <div className="imagebox">
                                    <img src="images/bxs-edit.png" />
                                </div>
                                <h3>Daily Order Log (DOL)</h3>
                            </div>
                            </Link>
                        </div>} */}
                        {/* {role <= 2 && <div className="col-md-3">
                            <Link to={"/import-non-support-carrier-number"}>
                            <div className="dashboard-box" id="orderbox">
                                <div className="imagebox">
                                    <img src="images/bxs-cart.png" />
                                </div>
                                <h3>Import Non Supported Carrier Number</h3>
                            </div>
                            </Link>
                        </div>} */}
                        
                    </div>
                </div>
            </div>
            {/* <h1>Welcome Back: {name}</h1>
            <table className="table is-striped is-fullwidth">
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr key={user.id}>
                            <td>{index + 1}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                        </tr>
                    ))}
 
                </tbody>
            </table> */}
        </div>
    )
}
 
export default Index