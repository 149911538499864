import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
// import Pagination from "react-js-pagination";
import { useNavigate, Link } from 'react-router-dom';
import formatDateTimeToPST from '../PSTDateConvert';
import Pagination from 'react-js-pagination';
import { saveAs } from 'file-saver';
import {apiUrl} from "../../components/config";
import * as XLSX from "xlsx";


const ReconcileActiveNumbers = () => {
    const [name, setName] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [cid, setCid] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [reconcileHistory, setReconcileHistory] = useState([])
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [companyList, setcompanyList] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');

    const navigate = useNavigate();
    
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(token){
         getCustomerNameList();
        } 
    }, [token]);

    useEffect(() => {
        if (access.length > 0 && token) {
            checkAccess(access, token);
        }
    }, [access, token]);

    useEffect(() => {
        getReconcileActiveNumbersHistory();
    }, [page]);


    const getCustomerNameList = async () => {
        try {
          
            const response = await axios.get(`${baseUrl}/active-user-company`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            const allArray = response.data.filter(item => item.id !== 1);
            setcompanyList(allArray);
            
        } catch (error) {
           console.log(error);
            
        }
    };

    const handlePageChange = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }

    const getReconcileActiveNumbersHistory = async () => {
        setPopupOpen(true);
        const response = await axiosJWT.get(`${baseUrl}/reconcile-active-number-history?page=${page}&limit=${20}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        setPopupOpen(false);
        setReconcileHistory(response.data.data)
        setTotalItem(response.data.totalItem)
    }    

    const exportErrors = async (abx) => {
        console.log(abx, 'abx');
    
        const worksheet = XLSX.utils.aoa_to_sheet(abx);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Number List");

        // Write XLSX file
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        // Convert buffer to Blob
        const data = new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Save File
        saveAs(data, "reconcile_active_number.xlsx");
    };
    

    const getExportResult = async (id)=>{
        setPopupOpen(true)
        const response = await axiosJWT.get(`${baseUrl}/reconcile-active-number-export-result/${id}`,{
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data.data)
        const exportResult = response.data.data
        
        await exportErrors(exportResult)

        setPopupOpen(false)
    }

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);

            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
            setTimeZone(decoded.timezone)

        } catch (error) {

            if (error.response) {
                // setError("Unauthorized");
                navigate("/");
                window.location.reload();
            }
        }
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);

        } catch (error) {
            console.log(error);

        }
    }


    const checkAccess = async (access) => {
        if (role > 2) {
            navigate('/dashboard');
        }
    }

    const axiosJWT = axios.create();

    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    
    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);
    };
    
    const handleResumeSubmit = async() => {
        try{
            setPopupOpen(true);
            setButtonDisabled(true);
            const response = await axiosJWT.post(`${baseUrl}/check-reconcile-active-number-process`, {company_id:selectedValue, token: token, url: apiUrl},{
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            alert(response.data.message);                            
            setPopupOpen(false);
            
            window.location.reload();
            setButtonDisabled(false);
        }catch (error) {
            setButtonDisabled(false);
           console.log(error);
        }
    };

    return (
        <div className="panelbox">
            <Navbar token={token} access={access} />
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                    <Loader />
                )}
                <div className="content-page">

                    <div className="row">
                        <div className="col-8">
                            <div className="left-title">
                                <h1>Reconcile Active Numbers History</h1>
                            </div>
                        </div>                        
                        <div className="col-4 top-right-buttons">
                            <div className="export-btn addnew-button">
                                <Link to="/number-management"><span>&larr;</span> Back</Link>
                            </div>
                            
                        </div>

                    </div>
                    <div className="common-white-shadow-background setting-section">
                        <div className="row">
                            <div className='col-8'>                                
                                <div className='boxinput'>
                                    <select className="form-control" name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                                        <option value="">Select Company</option>
                                        {companyList.map((comp) => (                                                                    
                                            <option key={comp.id} value={comp.id}>
                                                {comp.cname}
                                            </option>  
                                        ))}
                                    </select>
                                    <button className={buttonDisabled ? 'search-btn disabled-link' : 'search-btn'} style={{marginLeft:'10px'}} onClick={handleResumeSubmit}>Reconcile</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="common-white-shadow-background setting-section">
                        <div className="row">
                            <div className=" setting-section haft-whitebox">
                                <div className="search-result-section">
                                    <h3></h3>
                                </div>
                                <table className="tablebox search-result-table">
                                    <thead>
                                        <tr>
                                            <th>Date ({timeZone})</th>
                                            <th>Company</th>
                                            <th>Name</th>
                                            <th>Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {reconcileHistory.map((importItem, index) => (
                                            <tr key={index}>
                                                <td>{formatDateTimeToPST(importItem.created_at, timeZone, role)}</td>
                                                <td>{importItem.cname}</td>
                                                <td>{importItem.full_name}</td>
                                                <td>
                                                    {importItem.status == 2 ? <button onClick={() => { getExportResult(importItem.path)}}>  
                                                        <img src={`${process.env.PUBLIC_URL}/images/download.png`} alt="" />

                                                    </button>: "In-Progress"}
                                                </td>
                                            </tr>))}
                                    </tbody>
                                </table>

                                {totalItem > 20 && (<Pagination
                                    activePage={page}
                                    itemsCountPerPage={20}
                                    totalItemsCount={totalItem}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange.bind(this)}
                                />)}

                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default ReconcileActiveNumbers