/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
// import importBaseUrl from "../ImportBaseUrl";
import Loader from "../../Loader";
// import Pagination from "react-js-pagination";
import { useNavigate, Link } from 'react-router-dom';

 
const ImportNoteIntoInventory = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [importType, setImportType] = useState(0);
    const [cid, setCid] = useState('');
    const [formData, setFormData] = useState([]);
    const [responseData, setResponseData] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [companyList, setcompanyList] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const [validationErrors, setValidationErrors] = useState({
        file: false,
        cname: false,
      });
    const [file, setFile] = useState('');

    useEffect(() => {
       if(token){
        getCustomerNameList();
       } 
    }, [token]);

    const getCustomerNameList = async () => {
        try {
          
            const response = await axios.get(`${baseUrl}/active-user-company`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            // const allArray = response.data.slice(1);;
            const allArray = response.data.filter(item => item.id !== 1);
            setcompanyList(allArray);
            // console.log(allArray);
            //     setLoading(false); 
            // if(allArray.statusCode == 200){
            //     console.log(allArray.data);
            //     setRoutingList(allArray.data)
            // }
           
            
            
          
        } catch (error) {
            setLoading(false);
           console.log(error);
            
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
      };

    const handleNextClick = async(e) => {
        e.preventDefault();
        const newValidationErrors = {
            file:file == '',
          };
         
          setValidationErrors(newValidationErrors);
          const hasErrors = Object.values(newValidationErrors).some((error) => error);
     
        if(!hasErrors){
           
            const newData = new FormData();
                newData.append("file", file);               
                //newData.append('company_id', selectedValue);
                setPopupOpen(true);
                // let response;
                // response =  axios.post(`${baseUrl}/add-note-into-tn-number-inventory`, newData,{
                //     headers: {
                //             Authorization: `Bearer ${token}`,
                //             "Content-Type": "multipart/form-data",
                //     }
                // });
                
                // console.log(response);
                    try {
                      const response = await axios.post(`${baseUrl}/add-note-into-tn-number-inventory`, newData, {
                        headers: {
                          Authorization: `Bearer ${token}`,
                          "Content-Type": "multipart/form-data",
                        },
                      });
                      if(response.data.errors.length > 0){
                        setResponseData(response.data.errors);
                      }else{
                        setResponseData(response.data);
                      }
                      console.log(response.data.errors); // Handle the response data
                    } catch (error) {
                      console.error("Error uploading note:", error);
                    }

                setPopupOpen(false);
                // const allArray = response.data;
                // console.log(allArray); 
                navigate("/order-new-number-success", { state: {
                    importNoteInventory : true
                } }); 
        }
       
    };

    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);
    };
    
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token]);
    
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        
        }
    }

    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if(role > 2 || !hasPermission('ordernewnumber')){
            navigate('/dashboard');
        }
    }

    
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const handleRadioChange = (e) => {
        setImportType(Number(e.target.value));
    };

 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page">
                    {/* {renderStep()} */}
                    <>
            <div className="row">
                <div className="col-8">
                    <div className="left-title">
                        <h1>Import Note into Inventoty</h1>
                    </div>
                </div>


                <div className="col-4 top-right-buttons">   
                    <div className="export-btn addnew-button"><Link to="/import-notes-into-inventory-history">History</Link></div>
                    <div className="export-btn addnew-button"><Link to="/number-management"><span>&larr;</span> Back</Link></div>
                    </div>  
                
            </div>
            
            <div className="common-white-shadow-background setting-section">
                <div className="row">
                    
                    <h3>Upload File (Using Template)</h3>
                    <div className="download-templte">
                    <a  className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/Add_Note_to_Tnnumber_Inventory_Template.xlsx`} download="Add_Note_to_Tnnumber_Inventory_Template.xlsx">Download Template</a><p>Submissions must be in the format of this template</p>
                    </div>
                    <div className="file-upload-field">
                        <div className="upload-btn-wrapper">
                              <input type="file" name="myfile" onChange={handleFileChange} placeholder="Select your local file based on our template" />
                            </div>
                    </div>
                    {validationErrors.file && (
        <p className="error">Please choose a file.</p>
      )}
                </div>
            </div>
            
            {responseData && Object.keys(responseData.errors || {}).length > 0 && (
                <p className="error">Failed to updating note: {JSON.stringify(responseData.errors, null, 2)}.</p>
            )}

            {responseData.allSuccess == true && <p className="success">Note has been added successfully.</p>}

            <div className="row footer-row">
                {/* <a href="#" className="Cancel">Cancel</a> */}
                <a href="#" className="next" onClick={handleNextClick}>Import</a>
            </div>
        </>
                </div>
            </div>
        </div>
    )
}
 
export default ImportNoteIntoInventory