/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import { useNavigate, Link } from 'react-router-dom';
 
const Index = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [cid, setCid] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();        
    }, []);
    useEffect(() => {
        if(role >= 3){
            getRedirectDashboard();
        }
    }, [role]);


    const getRedirectDashboard = () => {
        navigate("/dashboard");
    }
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
            setCid(decoded.cid);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            setPopupOpen(true);
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            setPopupOpen(false);
            console.log(response.data.results);
            setAccess(response.data.results);
            
        } catch (error) {
           console.log(error);
        
        }
    }
    
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
            setCid(decoded.cid);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
   
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>ENRMS Management</h1>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                    <div className="row numberboxes">
                         <div className="col-md-3">
                            <Link to="/enrms-group-management">
                            <div className="dashboard-box" id="number">
                                <div className="imagebox">
                                    <img src="images/bxs-phone.png" />
                                </div>
                                <h3>Device Groups</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>
                        
                        {/* <div className="col-md-3">
                        <Link to={"/enrms-carrier-management"}>
                            <div className="dashboard-box" id="bbdbox">
                                <div className="imagebox">
                                    <img src="images/bxs-phone-off.png" />
                                </div>
                                <h3>Carrier Settings</h3>
                            </div>
                            </Link>
                        </div> */}
                        {role < 2 && <div className="col-md-3">
                        <Link to={"/enrms-settings"} >
                            <div  className="dashboard-box"  id="viewtrans">
                                <div className="imagebox">
                                    <img src={`${process.env.PUBLIC_URL}/images/bxs-cloud.png`} alt="" />
                                </div>
                                <h3>Settings</h3>
                                
                            </div>
                            </Link></div>}
                        <div className="col-md-3">
                            <Link to={"/enrms-resume-management"} >
                            <div  className="dashboard-box"  id="number">
                                <div className="imagebox">
                                    <img src={`${process.env.PUBLIC_URL}/images/bxs-cloud.png`} alt="" />
                                </div>
                                <h3>Resume Management</h3>
                                
                            </div>
                            </Link>
                        </div>
                        <div className="col-md-3">
                        <Link to={"/enrms-remove-batch-number"}>
                            <div className="dashboard-box" id="bbdbox">
                                <div className="imagebox">
                                    <img src="images/bxs-phone-off.png" />
                                </div>
                                <h3>Remove Number From Batch</h3>
                            </div>
                            </Link>
                        </div>
                    </div>
                   
                    <div className="row numberboxes">
                        <div className="col-md-3">
                            <Link to="/enrms-device-management">
                            <div className="dashboard-box" id="bbdbox">
                                <div className="imagebox">
                                    <img src="images/bxs-phone.png" />
                                </div>
                                <h3>Device Management</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>
                        {role <= 2 && <div className="col-md-3">
                            <Link to="/enrms-image-api-log">
                            <div className="dashboard-box" id="number">
                                <div className="imagebox">
                                    <img src="images/bxs-phone.png" />
                                </div>
                                <h3>Image Upload Logs</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        {role <= 2 && <div className="col-md-3">
                            <Link to="/enrms-device-api-log">
                            <div className="dashboard-box" id="bbdbox">
                                <div className="imagebox">
                                    <img src="images/bxs-phone.png" />
                                </div>
                                <h3>Device Api Logs</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        {role <= 2 && <div className="col-md-3">
                            <Link to="/enrms-publish-management">
                            <div className="dashboard-box" id="orderbox">
                                <div className="imagebox">
                                    <img src="images/bxs-cart.png" />
                                </div>
                                <h3>Publish Management</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        
                        {role <= 2 && <div className="col-md-3">
                            <Link to="/enrms-schedule-summary">
                            <div className="dashboard-box" id="viewtrans">
                                <div className="imagebox">
                                    <img src="images/bxs-cloud.png" />
                                </div>
                                <h3>Schedule Summary</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        
                    </div>
                </div>
            </div>
          
        </div>
    )
}
 
export default Index