
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import baseUrl from "../BaseUrl";
import Loader from "../Loader";
import jwt_decode from "jwt-decode";
import Navbar from "../Navbar";
import Header from "../Header";
import {schedulerUrl} from "../config";
import { useNavigate, Link } from 'react-router-dom';
 
const ResumeManagement = () => {

    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [casAccess, setCASAccess] = useState(false);
    const [showNoAccessMessage,setShowNoMessageAccess] = useState(false);
    const [company, setCompany] = useState([]);
    const [access, setAccess] = useState([]);
    const [batchDates, setBatchDates] = useState([]);
    const [selectedBatchDate, setSelectedBatchDate] = useState("");
    const [batchList, setBatchList] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState("");
    const [callUnknownAgain, setCallUnknownAgain] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        refreshToken();
        getUserAccess();
        getUserCompany();
    }, []);

    useEffect(() => {
        if(role > 2){
            checkAccess();
        }
        
    }, [role]);

    useEffect(()=>{
        if(selectedCompany && token){
            getCiaCallHistoryDate(selectedCompany);
        }
    },[selectedCompany, token]);

    useEffect(()=>{
        if(selectedCompany && token){
            getCustomerCASAccess(selectedCompany);
        }
    },[selectedCompany, token]);

    

    useEffect(() => {
        if(selectedBatchDate && selectedCompany){
            getCiaCallHistoryBatchList(selectedCompany);
        }
    }, [selectedCompany, selectedBatchDate])

    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setRole(decoded.role);
            setName(decoded.name);
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }

    const getUserCompany = async () => {
        
        const response = await axiosJWT.get(`${baseUrl}/active-user-company`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        console.log(response.data);
        const allArray = response.data.filter(item => item.id !== 1);
        setCompany(allArray);
    }

    const handleCompanyChange = (e) => {
        setSelectedCompany(e.target.value);  
    };

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        //    setIsLoading(false);
            // if (error.response) {
            //     // setError("Unauthorized");
            //     navigate("/");  
            //     window.location.reload();
            // }
        }
    }

    const checkAccess = async () => {
        navigate('/dashboard');
    }

    const getCustomerCASAccess = async (cid) => {
        try {
            setPopupOpen(true);
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            console.log(response.data);
           
            if(response.data.cia_enable_switch === 1){
                if(response.data.cia_account_type === 0 && role > 2){
                        setCASAccess(false)
                        setShowNoMessageAccess(true)
                }else{
                    setCASAccess(true)
                    setShowNoMessageAccess(false)
                }
            }else{
                setCASAccess(false)
                setShowNoMessageAccess(true)
            }
            setPopupOpen(false);
        } catch (error) {
           console.log(error);
        }
    };
    

    const getCiaCallHistoryDate = async (cid) => {
        try {
            setPopupOpen(true);
            const response = await axiosJWT.get(`${baseUrl}/get-cia-call-history-dates/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            setBatchDates(response.data.result);
            setSelectedBatchDate( response.data.result[0].date);
            setPopupOpen(false);
        } catch (error) {
           console.log(error);
        }
    }

    const getCiaCallHistoryBatchList = async (cid) => {
        try {
            setPopupOpen(true);
            console.log(cid);
            const response = await axiosJWT.post(`${baseUrl}/get-cia-call-history-batch/${cid}`, {date: selectedBatchDate}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            
            setBatchList(response.data.result);
            setSelectedBatch( response.data.result[0].id);
            setPopupOpen(false);
        } catch (error) {
           console.log(error);
        }
    }

    const handleResumeSubmit = async() => {
        try{
            setPopupOpen(true);
            setButtonDisabled(true);
            const response = await axiosJWT.post(`${baseUrl}/get-cia-resume-process`, {
                batch: selectedBatch,
                batchDate: selectedBatchDate,
                cid: selectedCompany,
                callUnknownAgain:callUnknownAgain
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            if(response.data.status == false){
                const message = response.data.data.map(obj => `Batch ID: ${obj.batch_id}, Type: ${obj.type}`).join("\n");
                alert(`${response.data.message}\n\n` + message);
            }else{
                alert(response.data.message);
            }
            setPopupOpen(false);
            // console.log(selectedBatch, selectedBatchDate, selectedCompany, callUnknownAgain);
            // axios.post(schedulerUrl, {
            //     batch: selectedBatch,
            //     batchDate: selectedBatchDate,
            //     cid: selectedCompany,
            //     callUnknownAgain:callUnknownAgain
            // });
            // alert("Process Resume for selected batch");
            window.location.reload();
            setButtonDisabled(false);
        }catch (error) {
            setButtonDisabled(false);
           console.log(error);
        }
    };

    const handleCheckboxChange = (event) => {
        setCallUnknownAgain(event.target.checked);
       
    };
    

    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
    return (
        <div className="panelbox">
            <Navbar token={token} access={access}/>
            <div className="right-panel">
                <Header name={name} />
                {popupOpen && (
                    <Loader /> 
                ) }
                <div className="content-page admin-attendance-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Resume Run Management</h1>
                            </div>
                        </div>
                        <div className="col-6 inventory-btn">
                            <div className="addnew-button">
                                <Link to="/enrms-management"><span>&larr;</span> Back</Link>
                            </div>
                        </div>
                    </div>
                    {(role <= 2) && <div className="common-white-shadow-background setting-section">
                        <div className="row">
                            {/* <div className="">
                                <p className="font-20-px">Batch Management</p>
                            </div><hr /> */}
                            <h3 className='comapny-label'>Select Company</h3>
                            <div className="inputbox">
                                <select name="" id=""  value={selectedCompany} onChange={(e) => handleCompanyChange(e)}>
                                    <option value="">Select Company</option>
                                    {company.map((comp) => ( 
                                            <option key={comp.id} value={comp.id}>
                                                {comp.cname}
                                            </option>  
                                        ))}
                                </select>
                            </div>
                        </div>

                        

                    
                    </div>}
                    {casAccess && <div className='common-white-shadow-background setting-section'>
                        <div className='row'>
                            <div><strong>Resume Process</strong></div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='boxinput'>
                                    <select className="form-control disabled-link" name='carrierStatus' value={selectedBatchDate} onChange={(e)=>{setSelectedBatchDate(e.target.value)}}>
                                        {batchDates && batchDates.map((item) => (
                                        <option value={item.date}>{item.date.split(" ")[0]}</option>
                                        ))}
                                    </select>
                                    <select className="form-control disabled-link" name='carrierStatus' value={selectedBatch} onChange={(e)=>{setSelectedBatch(e.target.value)}}>
                                        {batchList && batchList.map((item) => (
                                        <option value={item.id}>{item.id}</option>
                                        ))}
                                    </select>
                                    <label style={{ width:'50%', display: 'flex', alignItems: 'center', gap: '10px', margin: 10 }}>
                                        Call unknown again
                                        <input 
                                            type="checkbox" 
                                            style={{width: "25px"}} 
                                            name="call_unknown_again" 
                                            onChange={handleCheckboxChange}
                                        />
                                    </label>
                                    <button className={buttonDisabled ? 'search-btn disabled-link' : 'search-btn'} style={{marginLeft:'10px'}} onClick={handleResumeSubmit}>Resume</button>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {showNoAccessMessage && 
                        <div className="common-white-shadow-background setting-section">
                            <div className="row error">You don't have access to the features.</div>
                        </div>}
                </div>
        
            </div>
            </div>
    )
}
 
export default ResumeManagement
