/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
// import importBaseUrl from "../ImportBaseUrl";
import Loader from "../../Loader";
// import Pagination from "react-js-pagination";
import { useNavigate, Link } from 'react-router-dom';

 
const BulkNumberChangeRoute = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);
    const [cid, setCid] = useState('');
    const [formData, setFormData] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [companyList, setcompanyList] = useState([]);
    const [errorList, setErrorList] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const [popupOpen, setPopupOpen] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [validationErrors, setValidationErrors] = useState({
        file: false,
        cname: false,
      });
    const [file, setFile] = useState('');

    const errorListRef = useRef(null);

    useEffect(() => {
       if(token){
        getCustomerNameList();
       } 
    }, [token]);

    useEffect(() => {
        // Scroll to the errorListRef if Object.entries(errorList).length > 0
        if (Object.entries(errorList).length > 0) {
            console.log("scroll");
          errorListRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, [errorList]);

    const getCustomerNameList = async () => {
        try {
          
            const response = await axios.get(`${baseUrl}/active-user-company`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            // const allArray = response.data.slice(1);;
            const allArray = response.data.filter(item => item.id !== 1);
            setcompanyList(allArray);
            // console.log(allArray);
            //     setLoading(false); 
            // if(allArray.statusCode == 200){
            //     console.log(allArray.data);
            //     setRoutingList(allArray.data)
            // }
           
            
            
          
        } catch (error) {
            setLoading(false);
           console.log(error);
            
        }
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
      };

    const handleNextClick = async(e) => {
        e.preventDefault();
        const newValidationErrors = {
            file:file == '',
            cname: selectedValue === '',
            

          };
         
          setValidationErrors(newValidationErrors);
          const hasErrors = Object.values(newValidationErrors).some((error) => error);
     
        if(!hasErrors){
           
            const newData = new FormData();
                newData.append("file", file);               
                newData.append('company_id', selectedValue);
                setButtonDisabled(true);
                setPopupOpen(true);
                
                const response = await axios.post(`${baseUrl}/validate-bulk-tnnumber-change-route`, newData,{
                    headers: {
                         Authorization: `Bearer ${token}`,
                         "Content-Type": "multipart/form-data",
                    }
                });
                console.log(response.data);
                
                const allArray = response.data;
                if(allArray.data){
                    const selectedItems = allArray.data.map(obj => {
                        // Retrieve the first (and only) value from the object
                        const value = obj["Enter numbers only (e.g. 5555555555)"];
                        return { tn: value };
                    });
                    navigate(`/edit-multiple-trunk-detail`, { state: {selectedItems: selectedItems, cid:selectedValue}});
                    // setPopupOpen(false);
                }else{
                    setErrorList(allArray);
                    setPopupOpen(false);
                    setButtonDisabled(false);
                }
                
                // const allArray = response.data;
                // console.log(allArray); 
                // navigate("/order-new-number-success", { state: {
                //     importInventory : true
                // } }); 
        }
       
    };

    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);
    };
    
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();
    }, []);

    useEffect(() => {
        if(access.length > 0 && token){
            checkAccess(access, token);
        }
    }, [access, token]);
    
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        } catch (error) {
           
            if (error.response) {
                // setError("Unauthorized");
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            console.log(response.data.results);
            setAccess(response.data.results);
            // setIsLoading(false);
            
        } catch (error) {
           console.log(error);
        
        }
    }

    const hasPermission = (permission) => {
        return access.some((item) => item.access === permission);
    };

    const checkAccess = async (access) => {
        if(role > 2 || !hasPermission('ordernewnumber')){
            navigate('/dashboard');
        }
    }

    
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setCid(decoded.cid);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

  

 
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page">
                    {/* {renderStep()} */}
                    <>
            <div className="row">
                <div className="col-8">
                    <div className="left-title">
                        <h1>Bulk Number Change Route</h1>
                    </div>
                </div>


                <div className="col-4 top-right-buttons">   
                    <div className="export-btn addnew-button"><Link to="/imports-list"><span>&larr;</span> Back</Link></div>
                </div>  
                
            </div>
            {role <= 2 && <div className="common-white-shadow-background setting-section">
            <div className="row">
                <h3 className='comapny-label'>Select Company</h3>
                <div className="inputbox">
                    <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                        <option value="">Select Company</option>
                        {companyList.map((comp) => (                                                                    
                                <option key={comp.id} value={comp.id}>
                                    {comp.cname}
                                </option>  
                            ))}
                     
                    </select>
                </div>
            </div>
            {validationErrors.cname && (
        <p className="error">Please select a value from the dropdown.</p>
      )}
        </div>}
            
            
            <div className="common-white-shadow-background setting-section">
                <div className="row">
                    
                    <h3>Upload File (Using Template)</h3>
                    <div className="download-templte">                        
                        <a  className="newrow" target="_blank" href={`${process.env.PUBLIC_URL}/template/ImportInventory_Others_Numbers_Template.xlsx`}>Download Template</a>
                        <p>Submissions must be in the format of this template</p>
                    </div>
                    <div className="file-upload-field">
                        <div className="upload-btn-wrapper">
                              <input type="file" name="myfile" onChange={handleFileChange} placeholder="Select your local file based on our template" />
                            </div>
                    </div>
                    {validationErrors.file && (
        <p className="error">Please choose a file.</p>
      )}
                </div>
            </div>
            
            <div className="row footer-row">
                {/* <a href="#" className="Cancel">Cancel</a> */}
                <a href="#" className={buttonDisabled ? 'next disabled-link' : 'next'} onClick={handleNextClick}>Next</a>
            </div>
            <div style={{'color':"red","paddingBottom":"30px"}} ref={errorListRef}>{ Object.entries(errorList).length > 0 && <p>Please resolve below errors</p>}<table  className="tablebox search-result-table order-result-table">
                                        
                                        <tbody>
                                            {Object.entries(errorList).map(([key, value]) => (
                                                 key !== 'statusCode' && key !== 'show' && (
                                              <tr key={key}>
                                                <td><strong>{key}:</strong></td>
                                                <td >{key === 'status' ? (
            value
              .split(',')
              .map((item, index) => (
                <p key={index}>{item.trim()}</p>
              ))
          )  :
            key === 'result' && Array.isArray(value) ? (
              value.map((item, index) => (
                <p key={index}>{item}</p>
              ))
            ) : (
              value
            )}</td>
                                              </tr>
                                            )))}
                                        </tbody>
                                    </table></div>
        </>
                </div>
            </div>
        </div>
    )
}
 
export default BulkNumberChangeRoute