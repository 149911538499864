/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import { useNavigate, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";

const CDRAnalytics = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [expire, setExpire] = useState('');
    const [cid, setCid] = useState('');
    const [cdrReportList, setCDRReportList] = useState([]);    
    const [role, setRole] = useState('');
    
    const [access, setAccess] = useState([]);
    const [numberManagementAccess, setNumberManagementAccess] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);    
    const [boundType, setBoundType] = useState(0);
    const [date, setDate] = useState('');
    const [formData, setFormData] = useState({
        date: "",
        time:"",
        duration: ""
    });
    const [selectedValue, setSelectedValue] = useState('');
    const [companyList, setcompanyList] = useState([]);
    const [popupOpen, setPopupOpen] = useState(false); 
    const [isFilter, setIsFilter] = useState();
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();        
        getUserAccess();
    }, []);
    useEffect(() => {
      
        if(token){
            getCustomerNameList();                   
        }
        
    }, [access, token]);    
    useEffect(() => {
        if(cid){
            getCustomerDigitMappingAccess();
        }
    }, [cid, numberManagementAccess]);

    useEffect(() => {
if(isFilter){
    getResourceState();
}
    }, [formData, isFilter]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded.exp);
            setName(decoded.name);
            // setSelectedValue(decoded.cid);
            setRole(decoded.role);
            setExpire(decoded.exp);
            setCid(decoded.cid);
        } catch (error) {
           
            if (error.response) {                
                navigate("/");  
                window.location.reload();
            }
        }
    }

    
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {        
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            // setSelectedValue(decoded.cid);
            setRole(decoded.role);
            setExpire(decoded.exp);
            setCid(decoded.cid);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const getCustomerDigitMappingAccess = async () => {
        try {            
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });    
            
            if(response.data.report_switch !== 1 || !numberManagementAccess){ 
                navigate('/dashboard');
            } 

        } catch (error) {
           console.log(error);            
        }
    };
   

    const getCustomerNameList = async () => {
        try {
            const response = await axios.get(`${baseUrl}/active-user-company`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            const allArray = response.data.filter(item => item.id !== 1);
            console.log(allArray);
            setcompanyList(allArray);
          
        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };
 
    const getResourceState = async () => {
        setPopupOpen(true);
        const response = await axios.post(`${baseUrl}/get-report-data`, {
            date :formData.date,
            time: formData.time,
            duration:formData.duration,
            cid: selectedValue
            }, 
            {headers: {
                Authorization: `Bearer ${token}`
            }
        });
        
        setPopupOpen(false);   
        setIsFilter();
        setCDRReportList(response.data.mapping);
              
    }

  


    const handlePageChange  = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setPage(pageNumber);
    }

    const getUserAccess = async () => {
        try {
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
           
            // console.log(response.data.results);
            setAccess(response.data.results);
            setNumberManagementAccess(response.data.number_management_switch == 0 ? false : true);
            setIsLoading(false);
            
        } catch (error) {
           console.log(error);
           setIsLoading(false);    
        }
    }

    
    // const hasPermission = (permission) => {
       
    //     return access.some((item) => item.access === permission);
    //   };
         
    // const checkAccess = async (access) => {
        
    // }
    // const handleBoundSearch = async(type, e) => {
    //     e.preventDefault();
    //     setBoundType(type); 
    //     setDate('');       
    //     getResourceState("");  
        
    // };


    // const handleSearchChange = (e) => {
    //     setSearchValue(e.target.value);
    // };
    const handleSearchSubmit = () => {

        if (!formData.date ) {
            alert('Please select date');
            return;
        }
        setPage(1);
        setIsFilter(true);
        // getResourceState();
    };

    const clearFilter = () => {
        
        // setDate('');
        setSelectedValue('');
        setFormData({
            date: "",
            time:"",
            duration: ""
        });
        setIsFilter(true);       
        // getResourceState();
    }

    
    const handleCDRSearch = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value
        }));
    }
    const handleCompanyChange = (e) => {
        setSelectedValue(e.target.value);                
    };
 
    return (
        <div className="panelbox">
           <Navbar token={token} access={access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                
            <div className="content-page admin-attendance-page">               
                <div className="row">
                    <div className="col-8">
                        <div className="left-title">
                            <h1>CDR Reports Statistics</h1>
                        </div>
                        
                    </div>
                    <div className="col-4 top-right-buttons">                                        
                        <div className="export-btn addnew-button"><Link to="/traffic-and-analytics-reporting"><span>&larr;</span> Back</Link></div>
                    </div>
                    
                </div>
                   
                <div className="fullrow">
               
                    <div className="row admin-staff-list-toppart">
                        <div className="col-md-8">
                            <div className="boxinput">
                           
                                <select name="" id=""  value={selectedValue} onChange={(e) => handleCompanyChange(e)}>
                                    <option value="">Select Company</option>
                                    {companyList.map((comp) => (                                                                    
                                        <option key={comp.id} value={comp.id}>
                                            {comp.cname}
                                        </option>  
                                    ))}
                                </select>
                           
                                <input type="date" name="date" value={formData.date} onChange={(e) => handleCDRSearch(e)} />
                                <input type="time" name="time" value={formData.time} onChange={(e) => handleCDRSearch(e)} />
                                <select className="form-control" value={formData.duration} name="duration" onChange={(e) => handleCDRSearch(e)}>
                                 <option value="" >Select Duration</option>                                    
                                 <option value="15">15 min</option>
                                 <option value="30">30 min</option>
                                 <option value="60">1 hr</option>
                                
                             </select>
                                
                                <button  className="search-btn" onClick={handleSearchSubmit}>Search</button>
                               { (selectedValue || formData.date || formData.time || formData.duration) && (<a className='search-btn clear-btn' onClick={clearFilter} >Clear</a>)}
                            </div>
                        </div>
                        
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="fullwidth-table track-table-body staff-listing-admin-table-body real-time-stats">                                
                                <div>{cdrReportList.map((item, index) => (
                                    <div key={index}>
                                        {(item.outbound || item.inbound) &&<div>
                                        <p>Start Time UTC:- {item.START_TIME}</p>
                                        <p>Answer Time UTC:- {item.ANSWER_TIME}</p>
                                        <p>Release Time UTC:- {item.RELEASE_TOD}</p>
                                        <p>Call Duration in Approx Seconds:- {item.CALL_DURATION_SECS}</p>
                                        <p>Release Cause Desc:- {item.RELEASE_CAUSE_FROM_STACK}</p>
                                        <p>Call Released By (Bye):- {item.FIRST_RELEASE_DIALOGUE}</p>
                                        <table>
                                            {item.outbound && <td>
                                                <p>--------------- Outbound  -------</p>
                                                <p>Customer Trunk :- {item.ORIG_TRUNK_ID}</p>
                                                <p>Source Number:- {item.ORIG_SOURCE_NUMBER}</p>
                                                <p>Customer IP:- {item.ORIG_SOURCE_HOST_NAME}</p>
                                                <p>Destination Number:- {item.ORIG_DESTINATION_NUMBER}</p>
                                                {/* <p>DESTINATION_HOST_NAME:- {item.ORIG_DESTINATION_HOST_NAME}</p>
                                                <p>CALL_ID:- {item.ORIG_CALL_ID}</p>
                                                <p>REMOTE_PAYLOAD_ADDRESS:- {item.ORIG_REMOTE_PAYLOAD_ADDRESS}</p>
                                                <p>REMOTE_PAYLOAD_UDP_PORT:- {item.ORIG_REMOTE_PAYLOAD_UDP_PORT}</p>
                                                <p>LOCAL_PAYLOAD_ADDRESS:- {item.ORIG_LOCAL_PAYLOAD_ADDRESS}</p>
                                                <p>LOCAL_PAYLOAD_UDP_PORT:- {item.ORIG_LOCAL_PAYLOAD_UDP_PORT}</p>
                                                <p>CODEC_LIST:- {item.ORIG_CODEC_LIST}</p>
                                                <p>INGRESS_PACKETS:- {item.ORIG_INGRESS_PACKETS}</p>
                                                <p>EGRESS_PACKETS:- {item.ORIG_EGRESS_PACKETS}</p>
                                                <p>INGRESS_PACKET_LOSS:- {item.ORIG_INGRESS_PACKET_LOSS}</p>
                                                <p>INGRESS_DELAY:- {item.ORIG_INGRESS_DELAY}</p> */}
                                                {/* <p>INGRESS_PACKET_JITTER:- {item.ORIG_INGRESS_PACKET_JITTER}</p> */}
                                            </td>}
                                            {item.inbound && <td>
                                                <p>------------------ Inbound --------</p>
                                                <p>Customer Trunk:- {item.TERM_TRUNK_ID}</p>
                                                <p>Source Number:- {item.TERM_SOURCE_NUMBER}</p>
                                                <p>Customer IP:- {item.TERM_SOURCE_HOST_NAME}</p>
                                                <p>Destination Number:- {item.TERM_DESTINATION_NUMBER}</p>
                                                {/* <p>DESTINATION_HOST_NAME:- {item.TERM_DESTINATION_HOST_NAME}</p>
                                                <p>REMOTE_PAYLOAD_IP:- {item.TERM_REMOTE_PAYLOAD_IP}</p>
                                                <p>REMOTE_PAYLOAD_UDP_PORT:- {item.TERM_REMOTE_PAYLOAD_UDP_PORT}</p>
                                                <p>LOCAL_PAYLOAD_IP:- {item.TERM_LOCAL_PAYLOAD_IP}</p>
                                                <p>LOCAL_PAYLOAD_UDP_PORT:- {item.TERM_LOCAL_PAYLOAD_UDP_PORT}</p>
                                                <p>CODEC_LIST:- {item.TERM_CODEC_LIST}</p>
                                                <p>INGRESS_PACKETS:- {item.TERM_INGRESS_PACKETS}</p>
                                                <p>EGRESS_PACKETS:- {item.TERM_EGRESS_PACKETS}</p>
                                                <p>INGRESS_PACKET_LOSS:- {item.TERM_INGRESS_PACKET_LOSS}</p>
                                                <p>INGRESS_DELAY:- {item.TERM_INGRESS_DELAY}</p>
                                                <p>INGRESS_PACKET_JITTER:- {item.TERM_INGRESS_PACKET_JITTER}</p> */}
                                            </td>}
                                            {/* <td> */}
                                                {/* <p>--------------- Routing -------</p> */}
                                                {/* <p>FINAL_ROUTE_INDICATION:- {item.FINAL_ROUTE_INDICATION}</p> */}
                                                
                                                {/* <p>POST_DIAL_DELAY_SECS:- {item.POST_DIAL_DELAY_SECS}</p>
                                                <p>RING_TIME_SECS:- {item.RING_TIME_SECS}</p> */}
                                            {/* </td> */}
                                            {/* <td>
                                                <p>-------------- Packet Statistics --------</p>
                                                <p>ORIG_MOS:- {item.ORIG_MOS}</p>
                                                <p>TERM_MOS:- {item.TERM_MOS}</p>
                                            </td> */}
                                        </table>                                        
                                        <hr></hr>
                                        </div>}
                                    </div>
                                ))}
                                </div>
                                    
                                {totalItem > 20 && (<Pagination
                                  activePage={page}
                                  itemsCountPerPage={20}
                                  totalItemsCount={totalItem}
                                  pageRangeDisplayed={5}
                                  onChange={handlePageChange.bind(this)}
                                />)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>                
            </div>
        </div>
    )
}
 
export default CDRAnalytics