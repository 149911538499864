/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import jwt_decode from "jwt-decode";
import Navbar from "../../Navbar";
import Header from "../../Header";
import baseUrl from "../../BaseUrl";
import Loader from "../../Loader";
import { useNavigate, Link } from 'react-router-dom';
 
const Index = () => {
    const [name, setName] = useState('');
    const [token, setToken] = useState('');
    const [cid, setCid] = useState('');
    const [expire, setExpire] = useState('');
    const [role, setRole] = useState('');
    const [access, setAccess] = useState([]);    
    const [numberManagementAccess, setNumberManagementAccess] = useState(true);
    const [popupOpen, setPopupOpen] = useState(false);
    
    const navigate = useNavigate();
 
    useEffect(() => {
        refreshToken();
        getUserAccess();               
    }, []);

    useEffect(() => {
        if(cid){
            getCustomerDigitMappingAccess();
        }
    }, [cid, numberManagementAccess]);
 
    const refreshToken = async () => {
        try {
            const response = await axios.get(`${baseUrl}/token`);
            
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            console.log(decoded);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
            setCid(decoded.cid);
        } catch (error) {
           
            if (error.response) {
                navigate("/");  
                window.location.reload();
            }
        }
    }
    const getUserAccess = async () => {
        try {
            setPopupOpen(true);
            const response = await axiosJWT.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            setPopupOpen(false);
            console.log(response.data.results);
            setAccess(response.data.results);
            setNumberManagementAccess(response.data.number_management_switch == 0 ? false : true);
        } catch (error) {
           console.log(error);       
        }
    }
    
    const getCustomerDigitMappingAccess = async () => {
        try {
            // setApiCallCount((prevCount) => prevCount + 1);
            const response = await axios.get(`${baseUrl}/check-company-e911-access/${cid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
    
            const response2 = await axios.get(`${baseUrl}/user-access`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }       
            });
            
            if(response.data.report_switch !== 1 || (response.data.report_switch==1 && response2.data.user_report_switch !== 1) || !numberManagementAccess){ 
                navigate('/dashboard');
            } 

        } catch (error) {
            // setLoading(false);
            
           console.log(error);
            
        }
    };
 
    const axiosJWT = axios.create();
 
    axiosJWT.interceptors.request.use(async (config) => {
        const currentDate = new Date();
        if (expire * 1000 < currentDate.getTime()) {
            const response = await axios.get(`${baseUrl}/token`);
            config.headers.Authorization = `Bearer ${response.data.accessToken}`;
            setToken(response.data.accessToken);
            const decoded = jwt_decode(response.data.accessToken);
            setName(decoded.name);
            setRole(decoded.role)
            setExpire(decoded.exp);
            setCid(decoded.cid);
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });
 
    
    return (
        <div className="panelbox">
           <Navbar  token={token} access = {access}/>
            <div className="right-panel">
            <Header name={name} />
            {popupOpen && (
                     <Loader /> 
                ) }
                <div className="content-page">
                    <div className="row">
                        <div className="col-6">
                            <div className="left-title">
                                <h1>Traffic Analytics Reporting</h1>
                            </div>
                        </div>
                        <div className="col-6">

                        </div>
                    </div>
                    <div className="row numberboxes">
                        {<div className="col-md-3">
                            <Link to="/real-time-report">
                            <div className="dashboard-box" id="number">
                                <div className="imagebox">
                                    <img src="images/bxs-phone.png" />
                                </div>
                                <h3>Real Time Stats</h3>
                                {/* <p>Order and modify phone numbers and features.</p> */}
                            </div>
                            </Link>
                        </div>}
                        {<div className="col-md-3">
                            <Link to="/resource-report">
                            <div className="dashboard-box" id="viewtrans">
                                <div className="imagebox">
                                    <img src="images/bxs-edit.png" />
                                </div>
                                <h3>Resource Stats</h3>
                            </div>
                            </Link>
                        </div>}
                        {<div className="col-md-3">
                            <Link to="/cdr-report">
                            <div className="dashboard-box" id="userbox">
                                <div className="imagebox">
                                    <img src="images/bxs-paste.png" />
                                </div>
                                <h3>CDR Stats</h3>
                            </div>
                            </Link>
                        </div>}
                        
                    </div>
                </div>
            </div>            
        </div>
    )
}
 
export default Index